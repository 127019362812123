import React from 'react';
import './hero-gradient.scss';

const HeroGradient = ({ id }) => {
  return (
    <div className="hero-gradient">
      <div className="gradient-canvas">
        <canvas id={id} />
      </div>
      <div className="gradient-top-overlay"></div>
      <div className="gradient-bottom-overlay"></div>
    </div>
  );
};

export default HeroGradient;
